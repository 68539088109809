import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

export default function Navbar() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const { title } = data.site.siteMetadata

  return (
    <nav>
      <h1>{ title }</h1>
      <div className="links">
        <Link to="/">Home</Link>
        <Link to="https://www.atmanyoga.yoga/">À propos de Ātman Yoga</Link>       
        {/* <Link to="/about">À propos de Ātman Yoga</Link> */}
        {/* <Link to="/projects">Portfolio Projects</Link> */}
      </div>
    </nav>
  )
}
